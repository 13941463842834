<template>
  <div class="checkTaskDetail-box">
    <!-- 任务状态一栏 -->
    <div style="display: flex">
      <div
        class="taskStatusItem"
        v-for="(item, i) in taskStatusList"
        :key="item.name"
        :class="item.isSelect ? 'selected' : ''"
        @click="handleSelectTaskStatus(item, i)"
      >
        <span>{{ item.name }}（{{ item.num }}）</span>
      </div>
    </div>
    <!-- 表格一栏 -->
    <el-row style="margin: 20px 0">
      <el-table
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        :data="tableDataList"
      >
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column
          label="执行学校名称"
          prop="sysOrgSchoolName"
        ></el-table-column>
        <el-table-column
          label="所属区县"
          prop="sysOrgAreaName"
        ></el-table-column>
        <el-table-column label="实验室数量" prop="eqptStockSummary.sysOrgSchoolRoomCount"></el-table-column>
        <!-- <el-table-column
          label="班级数量"
          prop="classNum"
        ></el-table-column> -->
        <el-table-column
          label="仪器耗材配齐率"
          prop="eqptStockSummary.materialRate"
          :formatter="customFormatter"
        ></el-table-column>
        <el-table-column
          label="提交时间"
          prop="submitTime"
          :formatter="customFormatter"
        ></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scoped">
            <span>{{
              scoped.row.taskSubmitStatus == 1 ? "待提交" : "已提交"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" prop="schoolName">
          <template slot-scope="scope">
            <span class="blueBtn1" @click="handleCheckDetail(scope.row)"
              >查看详情</span
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <div style="text-align: center">
      <el-button
        @click="
          () => {
            this.$router.back();
          }
        "
        >返回</el-button
      >
    </div>
    <el-dialog
      title="盘点记录"
      :visible.sync="dialogVisible"
      top="20px"
      width="80vw"
    >
      <checkRecordDetail :tempTaskInfo="tempTaskInfo"></checkRecordDetail>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCurCheckPlanDataApi,
  getCheckPlanSummitStatusApi,
} from "@/api/taskManage/checkStockPlan.js";
// import { getTaskCheckAccountListApi } from "@/api/materialManage/checkRecord.js";
import { isEmpty, formatDate } from "@/utils/util";
import checkRecordDetail from "@/views/materialManage/checkRecord/checkRecordDetail.vue";
export default {
  name: "checkTaskDetail",
  components: {
    checkRecordDetail,
  },
  data() {
    return {
      taskInfo: "",
      taskStatusList: [
        {
          name: "全部",
          num: 0,
          isSelect: true,
        },
        {
          name: "未提交",
          num: 0,
          isSelect: false,
        },
        {
          name: "已提交",
          num: 0,
          isSelect: false,
        },
      ],
      tableDataList: [],
      dialogVisible: false,
      checkRecordTableData: [],
      pageInfo: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      tempTaskInfo: "",
      status: "",
    };
  },
  created() {
    this.taskInfo = JSON.parse(this.$route.query.taskInfo);
    this.fetchTaskDataList(this.taskInfo.planId, this.status);
    this.fetchSummitStatusData(this.taskInfo.planId);
  },
  methods: {
    // 获取任务数据
    fetchTaskDataList(planId, status) {
      getCurCheckPlanDataApi(planId, status).then((res) => {
        if (res.success) {
          this.tableDataList = res.data;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取提交数据
    fetchSummitStatusData(planId) {
      getCheckPlanSummitStatusApi(planId).then((res) => {
        if (res.success) {
          this.taskStatusList.forEach((it, i) => {
            if (i == 0) it.num = res.data.totalCount;
            if (i == 1) it.num = res.data.unSubmitCount;
            if (i == 2) it.num = res.data.submitCount;
          });
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取盘点任务详情数据
    // fetchCheckTaskDatailData(taskId) {
    //   getTaskCheckAccountListApi(
    //     this.pageInfo.pageIndex,
    //     this.pageInfo.pageSize,
    //     taskId
    //   ).then((res) => {
    //     if (res.success) {
    //       this.checkRecordTableData = res.data;
    //       this.pageInfo.pageTotal = res.total;
    //     } else {
    //       this.$message.error(res.msg);
    //       return;
    //     }
    //   });
    // },
    // 选择任务状态
    handleSelectTaskStatus(item, i) {
      this.taskStatusList.forEach((item) => {
        item.isSelect = false;
      });
      this.taskStatusList[i].isSelect = true;
      if(i == 0){
        this.status = ""
      }else {
        this.status = i 
      }
      this.fetchTaskDataList(this.taskInfo.planId, this.status);
    },
    // 点击查看详情
    handleCheckDetail(row) {
      // this.fetchCheckTaskDatailData(row.taskId);
      this.tempTaskInfo = row;
      this.dialogVisible = true;
      // this.$router.push({
      //   path: `/materialManage/checkRecordDetail?
      //   checkInfo=${JSON.stringify(row)}`,
      // });
    },
    handleSizeChangePageInfo(val) {},
    handleCurrentChangePageInfo(val) {},
    //格式化内容
    customFormatter(row, column) {
      if (isEmpty(row)) {
        return;
      }
      switch (column.property) {
        case "submitTime":
          if (isEmpty(row.submitTime)) {
            return "/";
          }
          return formatDate(new Date(row.submitTime), "yyyy/MM/dd");
        case "eqptStockSummary.materialRate":
           return (row.eqptStockSummary.materialRate * 100).toFixed(2) + "%";    
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.taskStatusItem {
  width: 122px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #ccc;
  span {
    font-size: 14px;
  }
  &.selected {
    border-color: rgb(84, 173, 255);
    color: rgb(84, 173, 255);
  }
}
.taskStatusItem:nth-child(1) {
  border-radius: 5px 0 0 5px;
}
.taskStatusItem:nth-child(4) {
  border-radius: 0 5px 5px 0;
}
.blueBtn1 {
  color: #00f;
  text-decoration: underline;
  cursor: pointer;
}
.schoolTitle {
  border-left: 4px solid #00f;
  padding-left: 10px;
  height: 30px;
  line-height: 30px;
  font-size: 22px;
  font-weight: 700;
}
</style>
